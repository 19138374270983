// 3rd-party Plugins
// @import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./assets/sass/style.react.scss";

// Default Layout themes
@import "./assets/sass/themes/layout/header/base/light.scss";
@import "./assets/sass/themes/layout/header/menu/light.scss";
@import "./assets/sass/themes/layout/brand/dark.scss";
@import "./assets/sass/themes/layout/aside/dark.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  @import "./assets/sass/themes/layout/brand/dark.scss";
}

// Light
.brand-light {
  @import "./assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;

  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

body {
  background-color: #eef0f8 !important;
}

.json > pre {
  background: none #fff !important;
}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;
    white-space: nowrap;
    min-width: 100px;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: #3699ff !important;
    }
  }

  .table.table-head-custom thead th,
  .table.table-head-custom thead tr {
    font-weight: 600;
    color: #b5b5c3 !important;
    font-size: 12px;
    letter-spacing: 0.1rem;
  }

  .table.table-head-custom tbody tr,
  .table.table-head-custom tbody td {
    color: #464e5f;
    font-weight: 600;
    font-size: 14px;
    font-stretch: normal;
  }
}

.cursor-default {
  cursor: default !important;
}

.display-block {
  display: block;
}

.top-select {
  min-width: 75px;
}

.react-bootstrap-table-pagination-list {
  display: flex;
  justify-content: flex-end;
}

.action-btn {
  background-color: #009688;
}

.action-btn svg rect {
  fill: white;
}

.navigation-icon svg,
.action-icon svg {
  width: 1.5rem;
  height: 1.5rem;
}

.icon-white path {
  fill: white;
}

.upload-image button:hover {
  background-color: transparent;
}

.ql-container {
  height: 12rem !important;
}

.ws-nowrap {
  white-space: nowrap;
}

.logo {
  width: 4rem;
  height: auto;
}

.image-add-icon {
  transform: scale(4);
  padding: 0 !important;
  margin: 1rem !important;
}

.row-hover tbody tr:hover {
  color: black;
  cursor: pointer;
}

.delete-image-btn {
  right: 0;
  top: 0;
  background-color: #c6c6c6 !important;
  color: red !important;
  padding: 4px !important;
  border-radius: 4px !important;

  & svg {
    font-size: 2.25rem;
  }
}

.fit-content {
  width: fit-content !important;
}

.delete-action-btn {
  color: red !important;
  padding: 4px !important;
  border-radius: 4px !important;

  &:hover {
    background-color: white !important;
  }

  & svg {
    font-size: 2.25rem;
  }
}

.edit-action-btn {
  color: gray !important;
  padding: 4px !important;
  border-radius: 2px !important;

  &:hover {
    background-color: white !important;
  }

  & svg {
    font-size: 2rem;
  }
}

select {
  -webkit-appearance: none;
  appearance: none;
}

.ck {
  height: 200px;
}

.width-5 {
  width: 8%;
}

.react-bootstrap-table .table.table-head-custom tbody tr,
.react-bootstrap-table .table.table-head-custom tbody td {
  // text-transform: capitalize;
}

body {
  font-family: "Poppins", sans-serif !important;
}

.font-size-11 {
  font-size: 11px !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #50aa68 !important;
  background-color: #50aa68 !important;
}

.custom-switch.custom-switch-lg .custom-control-label {
  padding-left: 0.75rem;
  padding-top: 0.15rem;
}

.custom-switch.custom-switch-lg .custom-control-label::before {
  border-radius: 1rem;
  height: 1.5rem;
  width: 2.5rem;
}

.custom-switch.custom-switch-lg .custom-control-label::after {
  border-radius: 0.65rem;
  height: calc(1.5rem - 4px);
  width: calc(1.5rem - 4px);
}

.custom-switch.custom-switch-lg
  .custom-control-input:checked
  ~ .custom-control-label::after {
  transform: translateX(1rem);
}

.font-label-color {
  color: #b5b5c3;
}

.dropdown-toggle::after {
  display: none !important;
}

.Aside-header {
  color: #cb4f4f;
}

.icon-bg {
  background-color: whitesmoke;
}

.table-responsive {
  overflow-x: visible !important;
  overflow-y: visible !important;
}

.select-disabled {
  > div {
    > div {
      background-color: #f3f6f9;
    }
  }
}

.phone-number {
  color: #6c757d;
  font-size: 12px;
  margin-bottom: 0%;
  margin: 0%;
  padding: 0%;
}

.user-name-booking {
  color: #464e5f;
  font-weight: 600;
  font-size: 14px;
  font-stretch: normal;
}

.btn-outline-dark:hover {
  background-color: white !important;
  border-color: black !important;
  color: black !important;
}

.btn-outline-dark:focus {
  background-color: white !important;
  border-color: black !important;
  color: black !important;
}

.package-header {
  height: 5vh;
  font-size: 30px;
  padding: 40px;
  color: dimgrey;
}

.purchase-edit-lable {
  margin-top: 1rem;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 700;
  color: rgb(165, 165, 165);
}

@media print {
  .hide_wprint {
    display: none !important;
  }
}
