.service-modal .modal-content {
  background-color: #f3f6f9 !important;
  width: 100%;
}

.select__control--is-disabled {
  background-color: #f3f6f9 !important;
}

.branch-modal .modal-content {
  background-color: #f3f6f9 !important;
  width: 500px !important;
}

.btn-car-primary {
  background-color: #8950fc;
  color: white;
  border-radius: 12px;
  width: 80px;
}

.btn-car-back {
  background-color: #dfe8ef;
  color: black;
  width: 80px;
}

.modal-header-text {
  color: black;
  font-size: 17px;
  font-weight: 600;
}

.modal-close {
  color: black;
  font-size: 20px;
  font-weight: 800;
  cursor: pointer;
}

.radio-btn {
  height: 23px;
  width: 23px;
}

input[type="radio"] {
  width: 20px;
  height: 20px;
}

.dropzone-img {
  background-color: white;
  height: 90px;
}

.dropzone-text {
  padding: 30px;
}

.time_picker {
  width: 100% !important;
}

.booking-modal-arrived-btn {
  background-color: #f0f0f0;
}
